@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}





body, html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

html{
   zoom: 1.1;
}


.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  z-index: -1;
  opacity: 0.7;
}


.fa-phone {
    transform: scaleX(-1);
}


/* mobile view */

@media (max-width: 1024px) {
  .about-section {
    padding-top: 16px; /* Adjust top padding for mobile */
  }
  .w-full {
    width: 100%;
  }
}


/* Enable scrolling on mobile */
@media (max-width: 1024px) {
  body, html {
    overflow: auto;
  }
}

/* floating icons darkmode */

.icon-cloud-container {
  color: white; /* Ensure text/icons are visible */
}

